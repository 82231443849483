import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-blog.js";
import Youtube from "../components/youtube-embed";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Listen to Dr. RK Prasad on HIV phobia and the way to tackle it.`}</p>
    <Youtube video="https://www.youtube.com/embed/QteLXP_gl8s" mdxType="Youtube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      